import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FiqueAtentoForm.css';

function FiqueAtentoForm() {
  const [unidades, setUnidades] = useState([]);
  const [locais, setLocais] = useState([]);
  const [processos, setProcessos] = useState([]);
  const [tiposRegistro, setTiposRegistro] = useState([]); // Estado para tipos de registro
  const [potenciais, setPotenciais] = useState([]); // Estado para potenciais
  const [envolvidos, setEnvolvidos] = useState([]); // Estado para envolvidos
  const [filteredLocais, setFilteredLocais] = useState([]);
  const [filteredProcessos, setFilteredProcessos] = useState([]);
  const [unidadeId, setUnidadeId] = useState('');
  const [localId, setLocalId] = useState('');
  const [processoId, setProcessoId] = useState('');
  const [tipoRegistroId, setTipoRegistroId] = useState(''); // Estado para tipo de registro selecionado
  const [potencialId, setPotencialId] = useState(''); // Estado para potencial selecionado
  const [envolvidoId, setEnvolvidoId] = useState(''); // Estado para envolvido selecionado
  const [dataPoc, setDataPoc] = useState('');
  const [acaoRealizada, setAcaoRealizada] = useState('');
  const [descEvento, setDescEvento] = useState('');
  const navigate = useNavigate();

  const colaboradorId = localStorage.getItem('colaboradorId');

  useEffect(() => {
    if (!colaboradorId) {
      navigate('/login');
    }
    fetchUnidades();
    fetchLocais();
    fetchProcessos();
    fetchTiposRegistro();
    fetchPotenciais();
    fetchEnvolvidos(); // Carregar envolvidos
  }, [navigate, colaboradorId]);

  const fetchUnidades = async () => {
    try {
      const response = await fetch('https://poc.sagresrg.com.br/units', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.status === 'success') {
        const sortedUnidades = data.data.sort((a, b) => a.nome.localeCompare(b.nome));
        setUnidades(sortedUnidades);
      } else {
        console.error('Erro ao carregar unidades:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de unidades:', error);
    }
  };

  const fetchLocais = async () => {
    try {
      const response = await fetch('https://poc.sagresrg.com.br/locais', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.status === 'success') {
        const sortedLocais = data.data.sort((a, b) => a.nome.localeCompare(b.nome));
        setLocais(sortedLocais);
      } else {
        console.error('Erro ao carregar locais:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de locais:', error);
    }
  };

  const fetchProcessos = async () => {
    try {
      const response = await fetch('https://poc.sagresrg.com.br/processos', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.status === 'success') {
        const sortedProcessos = data.data.sort((a, b) => a.nome.localeCompare(b.nome));
        setProcessos(sortedProcessos);
      } else {
        console.error('Erro ao carregar processos:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de processos:', error);
    }
  };

  const fetchTiposRegistro = async () => {
    try {
      const response = await fetch('https://poc.sagresrg.com.br/tiporegistro', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.status === 'success') {
        setTiposRegistro(data.data);
      } else {
        console.error('Erro ao carregar tipos de registro:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de tipos de registro:', error);
    }
  };

  const fetchPotenciais = async () => {
    try {
      const response = await fetch('https://poc.sagresrg.com.br/potencial', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.status === 'success') {
        setPotenciais(data.data);
      } else {
        console.error('Erro ao carregar potenciais:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de potenciais:', error);
    }
  };

  const fetchEnvolvidos = async () => {
    try {
      const response = await fetch('https://poc.sagresrg.com.br/tipoenvolvido', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.status === 'success') {
        setEnvolvidos(data.data);
      } else {
        console.error('Erro ao carregar tipos de envolvidos:', data.message);
      }
    } catch (error) {
      console.error('Erro na requisição de tipos de envolvidos:', error);
    }
  };

  useEffect(() => {
    if (unidadeId) {
      const filteredLocals = locais.filter((local) => local.unidades_id === parseInt(unidadeId, 10));
      setFilteredLocais(filteredLocals);

      const filteredProcs = processos.filter((processo) => processo.unidades_id === parseInt(unidadeId, 10));
      setFilteredProcessos(filteredProcs);
    } else {
      setFilteredLocais([]);
      setFilteredProcessos([]);
    }
  }, [unidadeId, locais, processos]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const payload = {
      app_registro_id: 1, // Campo fixo
      unidades_id: parseInt(unidadeId, 10), // Unidade selecionada
      locais_id: parseInt(localId, 10), // Local selecionado
      processos_id: parseInt(processoId, 10), // Processo selecionado
      tiporegistro_id: parseInt(tipoRegistroId, 10), // Tipo de registro selecionado
      potencial_id: parseInt(potencialId, 10), // Potencial selecionado
      tipoenvolvido_id: parseInt(envolvidoId, 10), // Envolvido selecionado
      datapoc: dataPoc, // Data selecionada
      desc_evento: descEvento.trim(), // Descrição do evento
      acao_realizada: acaoRealizada.trim(), // Ação realizada
      obser: parseInt(colaboradorId, 10), // ID do colaborador logado
    };
  
    try {
        const response = await fetch('https://poc.sagresrg.com.br/registros', {
          method: 'POST',
          headers: {
            Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
      
        const data = await response.json();
      
        if (response.ok && data.status === 'success') {
          alert('Registro enviado com sucesso!');
          navigate('/home');
        } else {
          alert('Erro ao enviar registro: ' + (data.message || 'Erro desconhecido no servidor.'));
        }
      } catch (error) {
        alert('Erro ao enviar registro. Tente novamente.');
      }
    };      

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="fique-atento-form-container">
      <h2>Fique Atento</h2>
      <button onClick={handleBack} className="back-button">Voltar</button>
      <form onSubmit={handleSubmit} className="fique-atento-form">
        <div className="form-group">
          <label>Unidade</label>
          <select
            value={unidadeId}
            onChange={(e) => setUnidadeId(e.target.value)}
            required
            className="form-control"
          >
            <option value="">Selecione uma unidade</option>
            {unidades.map((unidade) => (
              <option key={unidade.id} value={unidade.id}>
                {unidade.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Local</label>
          <select
            value={localId}
            onChange={(e) => setLocalId(e.target.value)}
            required
            className="form-control"
          >
            <option value="">Selecione um local</option>
            {filteredLocais.map((local) => (
              <option key={local.id} value={local.id}>
                {local.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Processo</label>
          <select
            value={processoId}
            onChange={(e) => setProcessoId(e.target.value)}
            required
            className="form-control"
          >
            <option value="">Selecione um processo</option>
            {filteredProcessos.map((processo) => (
              <option key={processo.id} value={processo.id}>
                {processo.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Classifique o Registro</label>
          <select
            value={tipoRegistroId}
            onChange={(e) => setTipoRegistroId(e.target.value)}
            required
            className="form-control"
          >
            <option value="">Selecione uma classificação</option>
            {tiposRegistro.map((tipo) => (
              <option key={tipo.id} value={tipo.id}>
                {tipo.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Potencial</label>
          <select
            value={potencialId}
            onChange={(e) => setPotencialId(e.target.value)}
            required
            className="form-control"
          >
            <option value="">Selecione o potencial</option>
            {potenciais.map((potencial) => (
              <option key={potencial.id} value={potencial.id}>
                {potencial.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Envolvido</label>
          <select
            value={envolvidoId}
            onChange={(e) => setEnvolvidoId(e.target.value)}
            required
            className="form-control"
          >
            <option value="">Selecione o tipo de envolvido</option>
            {envolvidos.map((envolvido) => (
              <option key={envolvido.id} value={envolvido.id}>
                {envolvido.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Data</label>
          <input
            type="date"
            value={dataPoc}
            onChange={(e) => setDataPoc(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Descrição do Evento</label>
          <textarea
            className="form-control"
            value={descEvento}
            onChange={(e) => setDescEvento(e.target.value)}
            placeholder="Descreva o evento ocorrido..."
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label>Ação Realizada</label>
          <textarea
            className="form-control"
            value={acaoRealizada}
            onChange={(e) => setAcaoRealizada(e.target.value)}
            placeholder="Descreva a ação realizada..."
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">
          Enviar
        </button>
      </form>
    </div>
  );
}

export default FiqueAtentoForm;
