import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PopupMessage from './PopupMessage';
import './Login.css';
import logo from '../assets/logoapp.png';
import footerImage from '../assets/logos.png';
import InputMask from 'react-input-mask';

const Login = () => {
  const [cpf, setCpf] = useState('');
  const [matricula, setMatricula] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Controle do popup
  const [newUser, setNewUser] = useState({
    name: '',
    matricula: '',
    cpf: '',
    unidade: '',
    departamento: '',
    cargo: '',
  }); // Dados do novo usuário
  const [successMessage, setSuccessMessage] = useState(null); // Mensagem de sucesso
  const [unidades, setUnidades] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [cargos, setCargos] = useState([]);

  const navigate = useNavigate();

  // Função para buscar dados das APIs de unidades, departamentos e cargos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
        };

        const [unidadesResponse, departamentosResponse, cargosResponse] = await Promise.all([
          fetch('https://poc.sagresrg.com.br/units', { headers }),
          fetch('https://poc.sagresrg.com.br/departaments', { headers }),
          fetch('https://poc.sagresrg.com.br/cargos', { headers }),
        ]);

        const unidadesData = await unidadesResponse.json();
        const departamentosData = await departamentosResponse.json();
        const cargosData = await cargosResponse.json();

        if (unidadesData.status === 'success') setUnidades(unidadesData.data || []);
        if (departamentosData.status === 'success') setDepartamentos(departamentosData.data || []);
        if (cargosData.status === 'success') setCargos(cargosData.data || []);
      } catch (error) {
        console.error('Erro ao buscar dados das APIs:', error);
      }
    };

    fetchData();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const cpfUnmasked = cpf.replace(/\D/g, ''); // Remove a máscara do CPF
    const matriculaNumber = parseInt(matricula, 10);

    try {
      const response = await fetch('https://poc.sagresrg.com.br/auth', {
        method: 'GET',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.status === 'success' && data.data.length > 0) {
        const user = data.data.find(
          (user) => user.login === cpfUnmasked && user.matricula === matriculaNumber
        );

        if (user) {
          localStorage.setItem('userName', user.name);
          localStorage.setItem('token', 'seuTokenAqui'); // Altere se necessário
          localStorage.setItem('colaboradorId', user.id);
          navigate('/home');
        } else {
          setErrorMessage('Credenciais incorretas');
        }
      } else {
        setErrorMessage('Nenhum usuário encontrado ou erro na resposta');
      }
    } catch (error) {
      setErrorMessage('Erro na conexão com o servidor');
    }
  };

  const handleOpenPopup = () => setIsPopupOpen(true); // Abre o popup
  const handleClosePopup = () => setIsPopupOpen(false); // Fecha o popup

  const handleNewUserSubmit = async () => {
    const cpfUnmasked = newUser.cpf.replace(/\D/g, ''); // Remove a máscara do CPF

    const payload = {
      name: newUser.name,
      login: cpfUnmasked,
      password: 'Sagres2025@!', // Senha padrão
      matricula: newUser.matricula,
      email: 'NOVOUSUARIO', // E-mail fixo
      active: 'N', // Define como não ativo por padrão
      system_unit_id: parseInt(newUser.unidade, 10), // ID da unidade
      departaments_id: parseInt(newUser.departamento, 10), // ID do departamento
      cargo_id: parseInt(newUser.cargo, 10), // ID do cargo
    };

    try {
      const response = await fetch('https://poc.sagresrg.com.br/auth', {
        method: 'POST',
        headers: {
          Authorization: 'Basic 06747ce234e5ccbfec0a3bd01ec63908d5cfc6b1287f4fbc9dbccf53ac35',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.status === 'success') {
        setSuccessMessage('Solicitação de usuário enviada!');
        setIsPopupOpen(false); // Fecha o popup
        setNewUser({ name: '', matricula: '', cpf: '', unidade: '', departamento: '', cargo: '' }); // Limpa os campos
      } else {
        alert('Erro ao cadastrar usuário: ' + (data.message || 'Erro desconhecido.'));
      }
    } catch (error) {
      console.error('Erro ao conectar à API:', error);
      alert('Erro ao conectar à API. Tente novamente.');
    }
  };

  return (
    <div className="login-container">
      {errorMessage && <PopupMessage message={errorMessage} onClose={() => setErrorMessage(null)} />}
      {successMessage && (
        <PopupMessage message={successMessage} onClose={() => setSuccessMessage(null)} />
      )}
      <div className="login-card">
        <img src={logo} alt="Logo do App" className="app-logo" />
        <h2 className="login-title">Login</h2>
        <form onSubmit={handleLogin} className="login-form">
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            className="login-input"
            placeholder="CPF"
            required
          />
          <input
            type="text"
            placeholder="Matrícula"
            value={matricula}
            onChange={(e) => setMatricula(e.target.value)}
            className="login-input"
            required
          />
          <button type="submit" className="login-button">Entrar</button>
        </form>
        <span className="register-link" onClick={handleOpenPopup}>
          Encontrou algum erro? Solicitar cadastro de usuário
        </span>
        <img src={footerImage} alt="Rodapé" className="footer-image" />
      </div>

      {isPopupOpen && (
        <div className="popup-container">
          <div className="popup-card">
            <h3>Solicitar cadastro de usuário</h3>
            <label htmlFor="name">Nome Completo</label>
            <input
              id="name"
              type="text"
              placeholder="Nome Completo"
              value={newUser.name}
              onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
              className="popup-input"
            />
            <label htmlFor="cpf">CPF</label>
            <InputMask
              id="cpf"
              mask="999.999.999-99"
              placeholder="CPF"
              value={newUser.cpf}
              onChange={(e) => setNewUser({ ...newUser, cpf: e.target.value })}
              className="popup-input"
            />
            <label htmlFor="matricula">Matrícula</label>
            <input
              id="matricula"
              type="text"
              placeholder="Matrícula"
              value={newUser.matricula}
              onChange={(e) => setNewUser({ ...newUser, matricula: e.target.value })}
              className="popup-input"
            />
            <label htmlFor="unidade">Unidade</label>
            <select
              id="unidade"
              value={newUser.unidade}
              onChange={(e) => setNewUser({ ...newUser, unidade: e.target.value })}
              className="popup-input"
            >
              <option value="">Selecione a Unidade</option>
              {unidades.map((unidade) => (
                <option key={unidade.id} value={unidade.id}>
                  {unidade.nome}
                </option>
              ))}
            </select>
            <label htmlFor="departamento">Departamento</label>
            <select
              id="departamento"
              value={newUser.departamento}
              onChange={(e) => setNewUser({ ...newUser, departamento: e.target.value })}
              className="popup-input"
            >
              <option value="">Selecione o Departamento</option>
              {departamentos.map((departamento) => (
                <option key={departamento.id} value={departamento.id}>
                  {departamento.name}
                </option>
              ))}
            </select>
            <label htmlFor="cargo">Cargo</label>
            <select
              id="cargo"
              value={newUser.cargo}
              onChange={(e) => setNewUser({ ...newUser, cargo: e.target.value })}
              className="popup-input"
            >
              <option value="">Selecione o Cargo</option>
              {cargos.map((cargo) => (
                <option key={cargo.id} value={cargo.id}>
                  {cargo.nome}
                </option>
              ))}
            </select>
            <button className="popup-button" onClick={handleNewUserSubmit}>
              Enviar
            </button>
            <button className="popup-button-close" onClick={handleClosePopup}>
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
